import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (feature-flag \"tooltip-button\")}}\n  <Button\n    @type=\"tertiary\"\n    class=\"me-1 text-secondary\"\n    @onClick={{this.toggleShouldShowTooltip}}\n    data-test-application=\"toggle-is-show-tooltip\"\n    title={{t \"header.show-tooltips\"}}\n  >\n    <img\n      class=\"text-secondary\"\n      src=\"/assets/icons/information.svg\"\n      alt={{t \"header.show-tooltips\"}}\n      data-test-omnivise-tickets-icon=\"information-icon\"\n    />\n  </Button>\n{{/if}}", {"contents":"{{#if (feature-flag \"tooltip-button\")}}\n  <Button\n    @type=\"tertiary\"\n    class=\"me-1 text-secondary\"\n    @onClick={{this.toggleShouldShowTooltip}}\n    data-test-application=\"toggle-is-show-tooltip\"\n    title={{t \"header.show-tooltips\"}}\n  >\n    <img\n      class=\"text-secondary\"\n      src=\"/assets/icons/information.svg\"\n      alt={{t \"header.show-tooltips\"}}\n      data-test-omnivise-tickets-icon=\"information-icon\"\n    />\n  </Button>\n{{/if}}","moduleName":"client-app-omnivise-web/components/header/tooltip.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/header/tooltip.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class HeaderTooltipComponent extends Component {
  @service('show-tooltip') showTooltipService;

  @action
  toggleShouldShowTooltip() {
    const currentValue = this.showTooltipService.shouldShowTooltip;
    this.showTooltipService.setShouldShowTooltip(!currentValue);
  }
}
