import Service, { inject as service } from '@ember/service';

export default class RouteChangeService extends Service {
  @service router;
  @service('omnivise-metrics') omniviseMetricsService;
  @service('url-state') urlStateService;
  @service userCentric;

  listenForRouteChanges() {
    this.router.on('routeDidChange', (transition) => {
      this.updateUrlStatesPerView(transition);
      this.trackPageView();
    });
  }

  updateUrlStatesPerView(transition) {
    this.urlStateService.updateUrlStatesPerView(transition);
  }

  trackPageView() {
    this.omniviseMetricsService.trackPageView();
  }
}
