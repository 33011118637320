import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';

export default class SaveRedirectUriModifier extends Modifier {
  @service session;
  @service router;

  modify(/*element, positional, named*/) {
    const currentRoute = this.router.currentRoute;
    if (currentRoute) {
      const { redirectUri } = currentRoute.queryParams;
      if (redirectUri) {
        this.session.redirectUri = redirectUri;
      }
    }
  }
}
