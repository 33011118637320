//Abstract class for defining interface
import IdentityProvider from 'client-app-omnivise-web/utils/identity-providers/idp-interface';
import config from 'client-app-omnivise-web/config/environment';
import localStorage from 'ember-local-storage-decorator';

export default class TestIdentityProvider extends IdentityProvider {
  @localStorage() accessTokens = {};
  @localStorage() provider = null;
  generateAccessToken(customClaims) {
    const defaults = {
      exp: Math.floor(Date.now().valueOf() / 1000) + 3600,
      iat: Math.floor(Date.now().valueOf() / 1000) - 3600,
      uid: 'FAKE_UID',
      sub: 'xxxx@siemens.com',
      first_name: 'Tom',
      last_name: 'Hamster',
    };
    const claims = Object.assign({}, defaults, customClaims);
    return 'a.' + window.btoa(JSON.stringify(claims)) + '.b';
  }

  get ownTrustZone() {
    return config.authConfig.ownAuthTrustZone;
  }

  login() {
    window.sessionStorage.removeItem('redirectUri');
    this.updateLocalStorageAccessToken(
      this.ownTrustZone,
      this.generateAccessToken(),
      'testIdp',
    );
  }

  logout() {
    // eslint-disable-next-line
    console.log('TestIdp logout');
  }

  update() {
    return this.generateAccessToken();
  }

  pickupSessionState() {
    const accessTokenInfo = {
      accessToken: this.generateAccessToken(),
      provider: 'testIdp',
    };
    window.sessionStorage.removeItem('redirectUri');
    return accessTokenInfo;
  }

  ensureAccessTokenFor(trustZone) {
    return this.generateAccessToken({ scopes: [trustZone] });
  }

  updateLocalStorageAccessToken(scope, token, provider) {
    this.accessTokens = {
      ...this.accessTokens,
      [scope]: token,
    };
    this.provider = provider;
  }
}
