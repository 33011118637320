import config from 'client-app-omnivise-web/config/environment';

export async function redirectToSiemensIdUrl() {
  window.location.href = config.authConfig.siemensIdUrl;
  await new Promise((resolve, reject) =>
    setTimeout(
      () => reject(new Error('Could not set window.location.href in time')),
      0,
    ),
  );
}
