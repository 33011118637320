import Service, { inject as service } from '@ember/service';
import config from 'client-app-omnivise-web/config/environment';

export default class OmniviseMetrics extends Service {
  @service router;
  @service metrics;
  @service session;
  @service features;
  @service cookies;

  get gaAdapter() {
    let userProperties = {};
    //ember-metrics configuration
    if (this.session.isAuthenticated) {
      userProperties = this.#getUserInfoProps();
    } else {
      userProperties = this.#getGuestUserInfoProps();
    }
    return {
      name: 'GoogleAnalyticsFour',
      environments: ['all'],
      config: {
        id: config.OWC_GA4_ID,
        options: {
          anonymize_ip: true,
          debug_mode: config.environment !== 'production',
          send_page_view: false,
          user_properties: userProperties,
        },
      },
    };
  }

  get ucPerformanceGAId() {
    return config.featureFlags['google-analytics']
      ? config.ucPerformanceGAId
      : null;
  }

  activate(userConsents = []) {
    this.deactivate();
    const adapters = [];

    userConsents.forEach((consent) => {
      if (this.ucPerformanceGAId && consent.id === this.ucPerformanceGAId) {
        adapters.push(this.gaAdapter);
      }
    });

    this.metrics.activateAdapters(adapters);
  }

  deactivate() {
    this.cookies?.clear('_ga', { domain: '.siemens-energy.com', path: '/' });
    this.cookies?.clear('_gat', { domain: '.siemens-energy.com', path: '/' });
    this.cookies?.clear('_gid', { domain: '.siemens-energy.com', path: '/' });
    // this will destroy GA metric but not clear the cookies..
    this.metrics.willDestroy();
  }

  trackPageView() {
    if (!this.features.isEnabled('google-analytics')) {
      return;
    }
    if (this.session.isAuthenticated) {
      this.#populateMetricsContextWithUserInfo();
    } else {
      this.#populateMetricsContextWithGuestUserInfo();
    }

    const page = this.router.currentURL;
    const title = this.router.currentRouteName || 'unknown';

    this.metrics.trackPage({ page, title });
  }

  trackHelpMaterialsUsageEvent(assetDescriptor, fileName) {
    if (!this.features.isEnabled('google-analytics')) {
      return;
    }
    this.metrics.trackEvent({
      event: 'help_materials',
      category: assetDescriptor.category,
      title: assetDescriptor.title,
      fileName,
    });
  }

  #populateMetricsContextWithUserInfo() {
    const userProperties = this.#getUserInfoProps();
    this.metrics.context['user_properties'] = userProperties;
  }

  #populateMetricsContextWithGuestUserInfo() {
    const userProperties = this.#getGuestUserInfoProps();
    this.metrics.context['user_properties'] = userProperties;
  }

  #isInternalUser(sub) {
    // Not sure how reliable this approach is.
    // Should be replaced with a more robust business rule that comes from the PO.
    return (
      sub?.endsWith('@siemens.com') || sub?.endsWith('@siemens-energy.com')
    );
  }

  #getUserInfoProps() {
    const { oid, email } = this.session.ownClaims;
    const { user } = this.session;
    const isInternalUser = this.#isInternalUser(email) + '';
    return {
      oid,
      gaUserId: user?.id,
      isInternal: isInternalUser,
    };
  }

  #getGuestUserInfoProps() {
    return {
      oid: 'Guest User',
      gaUserId: 'Guest User',
      isInternal: 'guest',
    };
  }
}
