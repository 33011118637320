import Model, { attr, hasMany } from '@ember-data/model';

export default class extends Model {
  @attr({ defaultValue: () => [] }) authScopes;
  @attr({ defaultValue: () => [] }) instanceConfiguration;
  @attr('string') downloadUrl;
  @attr('string') version;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  @hasMany('configured-component', {
    inverse: 'componentDescriptor',
    async: true,
  })
  configuredComponents;

  get releaseChannel() {
    const { id } = this;
    if (id.includes('@next')) {
      return 'next';
    } else if (id.includes('@latest')) {
      return 'latest';
    } else if (id.match(/[@](\d+\.)?(\d+\.)?(\*|\d+)/)) {
      return `fixed`;
    }
    return 'stable';
  }
}
