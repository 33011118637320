import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const DEFAULT_LOCALE = 'en-us';
export default class ViewRoute extends Route {
  @service store;
  @service router;
  @service featuresManager;
  @service locale;

  async model(parameters) {
    try {
      const internalView = await this.store.findRecord(
        'internal-view',
        parameters.view_id,
        {
          include: [
            'module',
            'configuredWidgets.configuredComponents.componentDescriptor',
          ].join(','),
        },
      );
      const module = await internalView.module;
      this.changeLocale(module.moduleOptions);
      this.featuresManager.setFeatures(module.moduleOptions);
      return { internalView };
    } catch (e) {
      if (e?.errors?.firstObject?.code === 403) {
        this.router.transitionTo('auth');
      }
      throw e;
    }
  }

  changeLocale(moduleOptions) {
    let locale;
    if (moduleOptions['multi-language'] === false) locale = DEFAULT_LOCALE;
    this.locale.updateLocale(locale);
  }
}
