import Model, { belongsTo, attr } from '@ember-data/model';

export default class extends Model {
  @attr('string') title;
  @belongsTo('module', {
    inverse: 'views',
    async: true,
    as: 'view',
  })
  module;
}
