import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-invalid-interactive }}\n<div ...attributes>\n\n  {{yield\n    (hash toggle=this.toggle close=this.close trigger=this.trigger)\n    to=\"trigger\"\n  }}\n\n  {{! Click on dialog backdrop registered as click on dialog itself by browser\n  this is a workaround to close dialog on backdrop click, but allow clicks\n  inside of dialog to be handled by the dialog content }}\n  <dialog\n    class={{this.mode}}\n    {{on \"close\" this.close}}\n    {{on \"click\" this.close}}\n    {{this.register}}\n  >\n    <div {{on \"click\" this.stopPropagation}}>\n      {{yield\n        (hash\n          toggle=this.toggle\n          close=this.close\n          detach=this.detach\n          attach=this.attach\n        )\n        to=\"content\"\n      }}\n    </div>\n  </dialog>\n\n</div>", {"contents":"{{! template-lint-disable no-invalid-interactive }}\n<div ...attributes>\n\n  {{yield\n    (hash toggle=this.toggle close=this.close trigger=this.trigger)\n    to=\"trigger\"\n  }}\n\n  {{! Click on dialog backdrop registered as click on dialog itself by browser\n  this is a workaround to close dialog on backdrop click, but allow clicks\n  inside of dialog to be handled by the dialog content }}\n  <dialog\n    class={{this.mode}}\n    {{on \"close\" this.close}}\n    {{on \"click\" this.close}}\n    {{this.register}}\n  >\n    <div {{on \"click\" this.stopPropagation}}>\n      {{yield\n        (hash\n          toggle=this.toggle\n          close=this.close\n          detach=this.detach\n          attach=this.attach\n        )\n        to=\"content\"\n      }}\n    </div>\n  </dialog>\n\n</div>","moduleName":"client-app-omnivise-web/components/header/menu-modal.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/header/menu-modal.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

const MAXIMIZED = 'maximized-dialog';
const TETHERED = 'tethered-dialog';
const DETACHED = 'detached-dialog';

export default class HeaderMenuModalComponent extends Component {
  @tracked mode = TETHERED;

  dialogElement = null;
  triggerElement = null;

  constructor() {
    super(...arguments);
    this.resetMode();
  }

  register = modifier((element) => {
    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (this.mode === TETHERED) {
          this.positionDialog();
        }
      });
    });

    this.dialogElement = element;

    resizeObserver.observe(window.document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  });

  trigger = modifier((element) => {
    this.triggerElement = element;
  });

  open() {
    this.dialogElement.showModal();

    if (this.mode === TETHERED) {
      this.positionDialog();
    }
  }

  @action
  close() {
    this.dialogElement.close();
    this.resetMode();
    this.args.onClose && this.args.onClose();
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  toggle() {
    if (this.dialogElement.hasAttribute('open')) {
      this.close();
    } else {
      this.open();
    }
  }

  @action
  detach() {
    this.mode = DETACHED;
    this.resetPosition();
  }

  @action
  attach() {
    this.mode = TETHERED;
    this.positionDialog();
  }

  positionDialog() {
    const triggerRect = this.triggerElement.getBoundingClientRect();
    const dialogRect = this.dialogElement.getBoundingClientRect();

    this.positionLeft = triggerRect.left - dialogRect.width + triggerRect.width;
    this.positionTop = triggerRect.bottom + 10;

    this.dialogElement.style.top = `${this.positionTop}px`;
    this.dialogElement.style.left = `${this.positionLeft}px`;
  }

  resetPosition() {
    this.dialogElement.style.removeProperty('top');
    this.dialogElement.style.removeProperty('left');
  }

  resetMode() {
    if (this.args.maximized) {
      this.mode = MAXIMIZED;
    } else {
      this.mode = TETHERED;
    }
  }
}
