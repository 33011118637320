import Service, { inject as service } from '@ember/service';

export default class UserCentricService extends Service {
  @service metrics;
  @service session;
  @service('omnivise-metrics') omniviseMetricsService;

  verifyUCEvents() {
    window.addEventListener(
      'UC_UI_INITIALIZED',
      this.activateAnalytics.bind(this),
    );
    window.addEventListener('UC_UI_CMP_EVENT', (event) => {
      if (event.detail.type === 'SAVE' || event.detail.type === 'ACCEPT_ALL') {
        this.activateAnalytics();
      } else if (event.detail.type === 'DENY_ALL') {
        this.deactivateAnalytics();
      }
    });
  }

  activateAnalytics() {
    const filteredConsents = window?.UC_UI?.getServicesBaseInfo()?.filter(
      (data) => !data.isEssential && data.consent.status,
    );

    this.omniviseMetricsService.activate(filteredConsents);
  }

  deactivateAnalytics() {
    this.omniviseMetricsService.deactivate();
  }

  openSecondLayerConsent() {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
      window.UC_UI.showSecondLayer();
    } else {
      window.addEventListener('UC_UI_INITIALIZED', function () {
        window.UC_UI.showSecondLayer();
      });
    }
  }

  onRestartEmbeddings() {
    window?.UC_UI?.restartEmbeddings();
  }
}
