import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Application extends Route {
  @service session;
  @service userCentric;
  @service intl;

  @service('route-change') routeChangeService;

  async beforeModel() {
    try {
      this.intl.setLocale(['en-us']);
      // eslint-disable-next-line
      console.log('application route');

      // Ensure that a valid session is picked up. This also ensures that a
      // valid access token for own trust zone is present.
      await this.session.pickupSessionState();

      if (this.session.isAuthenticated && this.session.redirectUri) {
        await this.session.redirectToDestinationAfterAuthentication();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('beforeModel application error', error);
    }
  }
  afterModel() {
    this.routeChangeService.listenForRouteChanges();

    //Call the usercentric
    this.userCentric.verifyUCEvents();
  }
}
