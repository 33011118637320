import Model, { attr, belongsTo } from '@ember-data/model';

export default class extends Model {
  @attr('string', {
    defaultValue: () => {
      return 'en-us';
    },
  })
  locale;

  @attr('string')
  timezone;

  @belongsTo('user', { async: false, inverse: 'preferences' })
  user;

  get serialized() {
    return {
      locale: this.locale,
      timezone: this.timezone,
    };
  }
}
