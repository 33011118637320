import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';

export default class RestartUcEmbeddingsModifier extends Modifier {
  @service userCentric;

  modify(/*element, positional, named*/) {
    this.userCentric.onRestartEmbeddings();
  }
}
