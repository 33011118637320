import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import normalizeLocaleToApi from 'client-app-omnivise-web/utils/get-normalized-locale';

export default class ModuleBadgeModel extends Model {
  @service intl;

  @attr('string') style;
  @attr('string') 'text_de-DE';
  @attr('string') 'text_en-US';

  @hasMany('module', { inverse: 'moduleBadges', async: true }) modules;

  get text() {
    const { locale } = this.intl;

    return this[`text_${normalizeLocaleToApi(locale.firstObject)}`];
  }
}
