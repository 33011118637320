import ApplicationAdapter from './application';
import fetch from 'fetch';

export default class Preference extends ApplicationAdapter {
  namespace = 'api/v1';

  updateRecord(_store, _type, snapshot) {
    const data = this.serialize(snapshot, { includeId: true });
    const userId = this.session.user.id;
    const url = `${this.host}/${this.namespace}/users/${userId}/preferences`;
    const headers = Object.assign({}, this.headers, {
      'Content-Type': 'application/json',
    });

    return new Promise(function (resolve, reject) {
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers,
      }).then(
        function (data) {
          resolve(data.json());
        },
        function (error) {
          reject(error);
        },
      );
    });
  }
}
