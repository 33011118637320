import Model, { belongsTo, hasMany } from '@ember-data/model';

export default class extends Model {
  @belongsTo('internal-view', { inverse: 'configuredWidgets', async: true })
  view;
  @hasMany('configured-component', {
    inverse: 'configuredWidget',
    async: true,
  })
  configuredComponents;
}
