import Service, { inject as service } from '@ember/service';
import fetch from 'fetch';
import { task } from 'ember-concurrency-decorators';
import logErrorUtil from 'client-app-omnivise-web/utils/log-error';
import getApiUrl from 'client-app-omnivise-web/utils/get-api-url';

const ENV_URL = 'env';
const OWC_MANAGEABLE_FEATURES_URL = 'OWC_MANAGEABLE_FEATURES';

export default class FeaturesManagerService extends Service {
  @service features;
  @service session;

  @task
  *loadManageableFeaturesList() {
    const url = new URL(getApiUrl(ENV_URL));
    url.search = new URLSearchParams({
      keys: OWC_MANAGEABLE_FEATURES_URL,
    });
    const response = yield fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.session.ownAccessToken}`,
      },
    });
    if (response.ok) {
      const responseJSON = yield response.json();
      this.defaultConfig = responseJSON['OWC_MANAGEABLE_FEATURES']
        ? JSON.parse(responseJSON['OWC_MANAGEABLE_FEATURES'])
        : {};
      return true;
    }
    const errorText = yield response.text();
    logErrorUtil.logError(errorText);
    return false;
  }

  async setFeatures(featuresConfig = {}) {
    if (!this.defaultConfig) await this.loadManageableFeaturesList.perform();
    Object.keys(this.defaultConfig).forEach((featureName) => {
      const featureValue =
        featuresConfig[featureName] === undefined
          ? this.defaultConfig[featureName].value
          : featuresConfig[featureName];

      featureValue
        ? this.features.enable(featureName)
        : this.features.disable(featureName);
    });
  }
}
