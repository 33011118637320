import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthIndexRoute extends Route {
  @service featuresManager;

  async beforeModel() {
    this.featuresManager.setFeatures();
    return true;
  }
}
