import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class UrlStateService extends Service {
  @service intl;
  @service session;

  updateLocale(locale) {
    let userLocale = this.session.user?.preferences?.locale;
    userLocale = userLocale ?? this.userBrowserLocale;
    this.intl.setLocale([locale ?? userLocale]);
  }

  get userBrowserLocale() {
    const browserLang = navigator.language.toLowerCase().split('-')[0];
    // only take care of the first part of the language code
    const supportedLocales = this.intl.locales;
    const locale = supportedLocales.find((supportedLocale) => {
      return supportedLocale.startsWith(browserLang);
    });
    return locale ?? supportedLocales[0];
  }
}
