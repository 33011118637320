import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

export default class CreateElementHelper extends Helper {
  element = null;

  compute([tagName = 'div'], attributes) {
    // initialize element on first computation
    if (this.element === null) {
      this.element = document.createElement(tagName);
    }

    const { element } = this;

    assert(
      'Tag name passed to create element helper must not change over time.',
      tagName.toUpperCase() === element.tagName,
    );

    for (const attribute in attributes) {
      const value = attributes[attribute];

      assert(
        `Values for attributes passed to {{create-element}} helper must be either strings, boolean, null or undefined. ${typeof value} given for attribute ${attribute}`,
        ['string', 'boolean'].includes(typeof value) ||
          [null, undefined].includes(value),
      );

      // update attribute
      if (value === null || value === undefined || value === false) {
        // remove attribute if value is `null`, `undefined` or `false`
        if (element.hasAttribute(attribute)) {
          element.removeAttribute(attribute);
        }
      } else {
        // update attribute if needed
        const serializedValue =
          typeof value === 'object' ? JSON.stringify(value) : value;

        if (serializedValue !== element.getAttribute(attribute)) {
          element.setAttribute(attribute, serializedValue);
        }
      }
    }

    return element;
  }
}
