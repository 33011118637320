import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';

export default class CurrentPageService extends Service {
  @service intl;
  @service router;
  @service store;

  // TODO: Needs to be refactored https://guides.emberjs.com/release/components/helper-functions/#toc_the-in-element-helper
  get header() {
    const { currentRouteName } = this.router;

    if (currentRouteName.startsWith('auth.view')) {
      const authViewRoute = this.router.currentRoute.find(
        (route) => route.name === 'auth.view',
      );

      const { view_id: viewId } = authViewRoute.params;
      const view = this.store.peekRecord('internal-view', viewId);
      if (view) {
        const moduleRef = view.belongsTo('module');
        const moduleId = moduleRef.id();

        let header = '';

        if (isPresent(moduleId)) {
          const module = this.store.peekRecord('module', moduleId);
          header = module.name;
        } else {
          assert('The relationship is not loaded.');
        }

        return header;
      }
      return null;
    }

    return this.intl.t(`heading-for-module.${currentRouteName}`);
  }
}
