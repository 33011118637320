import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthRoute extends Route {
  @service router;
  @service session;
  @service locale;
  @service features;

  async beforeModel(transition) {
    if (!this.session.isTokenValid) {
      this.session.redirectUri = transition.intent.url;
      return this.router.transitionTo('login');
    }
    await this.session.loadOwnUser();
    if (this.features.isEnabled('i18n')) {
      this.locale.updateLocale();
    }
    return true;
  }
}
